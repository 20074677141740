import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function setLoginPopupCache() {
    const now = new Date().getTime();
    localStorage.setItem('loginPopupDismissed', now);
}

export function checkLoginPopupCache() {
    const dismissedTime = localStorage.getItem('loginPopupDismissed');
    if (dismissedTime) {
        const now = new Date().getTime();
        const elapsed = now - dismissedTime;
        // 检查已经过去的时间是否小于24小时
        return elapsed < 24 * 60 * 60 * 1000;
    }
    return false;
}

export function setCache(key, value) {
    return Cookies.set(key, value)
}
export function getCache(key) {
    return Cookies.get(key)
}
export function removeCache(key) {
    return Cookies.remove(key)
}
