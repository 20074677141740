<template>
  <div>
    <el-dialog
        title="建议/反馈"
        :visible.sync="updateShow"
        :fullscreen="fullscreen"
        custom-class="dialog_class"
        :close-on-click-modal="closeOnclickModal"
        :append-to-body="appendToBody"
        width="30%"
        :before-close="onCancel"
    >
      <div>
        <p>~ 我是个人开发者，做的有不好的地方，请反馈给我，我会及时修复，谢谢</p>
        <p>~ 我会查看每一条建议，可能时间会有点久</p>
        <p>~ 如果很急，我的邮箱：jzcckk@163.com</p>
        <p>~ 如果真的很急，我的微信：<span style="color: red">vpb4wt</span>，我是社恐，真的，我只是喜欢敲代码</p>
      </div>
      <div>
        <el-form ref="form" :model="form" size="mini">
          <el-form-item>
            <el-input type="textarea" v-model="form.desc" :autosize="{ minRows: 6, maxRows: 10}"
                      placeholder="填写内容~~"></el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="onCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { suggestion } from '@/api/scanDomain'

export default {
  name: 'SuggestionPage',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullscreen: false,
      closeOnclickModal: false,
      appendToBody: true,
      updateShow: this.dialogVisible,
      form: {
        desc: '',
      },
    }
  },
  methods: {
    onCancel() {
      this.updateShow = false
      this.$emit('updateShow', false)
    },
    onSubmit() {
      suggestion(this.form).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          this.updateShow = false
          this.$emit('updateShow', false)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>
<style>
.dialog_class {
  border-radius: 12px;
}
</style>
