// axios的三层封装：第一层 => 对axios进行增强
import axios from "axios";
//引入全局的Message(Element UI)
import { Message } from "element-ui";
// 引入本地存储数据
import local from "@/utils/local.js";
import { getToken } from '@/utils/auth'
// 1.设置公共服务器路径
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// 2.添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 发请求时，自动携带token
    let token = getToken();
    config.headers['cheers-Token'] = '';
    if(token){
        config.headers.Authorization = 'Bearer ' + token;
        config.headers['cheers-Token'] = token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 3.添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数  // 访问成功时执行
    if(response.data.code === 401 || response.data.code === 402) {
        // 弹出登录弹框 跳到首页
        Message.error(response.data.msg);
        location.href = '/#/login';
        return ;
    }
    // 判断是否获取数据成功
    if(typeof (response.data.code) === "number"){  //如果response.data.code是数字就弹提示框，如果不是就不弹
        if(response.data.code === 0){
            Message.error(response.data.msg);
        }
    }
    return response.data;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数  // 访问失败时执行
    Message.error(error.message);
    return ;
    // return Promise.reject(error);
});

// 暴露增强后的axios
export default axios;
