<template>
  <div class="footer">
    <div class="footer-left">
      <div class="footer-left-item">
        Copyright 2022-2025 ©️ <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备17025769号-3</a> 子域名查询网.All
        rights reserved 联系我们：<a href="https://mail.163.com/" target="_blank">jzcckk@163.com</a>  <el-button type="text" size="small" @click="suggestion()">建议/反馈</el-button>
      </div>
    </div>

    <SuggestionPage
        v-if="dialogVisible"
        ref="identity"
        :dialog-visible.sync="dialogVisible"
        @updateShow="updateShow"
    />

  </div>
</template>
<script>
import SuggestionPage from '@/components/suggestionPage.vue'

export default {
  name: 'Footer',
  components: {SuggestionPage},
  data() {
    return {
      activeIndex: 'Banner',
      dialogVisible: false
    }
  },
  methods: {
    updateShow(val) {
      this.dialogVisible = val
    },
    suggestion() {
      this.dialogVisible = true
    }
  }
}

</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #0d1627;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;
  color:  #05f2f2;
  line-height: 40px;

  .footer-left {
    display: flex;

    .footer-left-item {
      margin-right: 10px;
    }

    .footer-left-item:last-child {
      margin-right: 0;
    }

    .footer-left-item a {
      color: #666;
    }

    .footer-left-item a:hover {
      color: #333;
    }

    .footer-left-item span {
      color: #666;
    }

    .footer-left-item span:hover {
      color: #333;
    }
  }
}
</style>
