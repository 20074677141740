import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        name: '子域名查询-网络测绘',
        component: () => import('../components/Index.vue')
    },
    {
        path: '/login',
        title: '登录',
        component: () => import('../components/Login/Login.vue')  //组件页面地址
    },
    {
        path: '/userInfo',
        title: '个人中心',
        component: () => import('../components/UserInfo/userInfo.vue')  //组件页面地址
    },
    {
        path: '/search',
        title: '检索',
        component: () => import('../components/Search/Search.vue')  //组件页面地址
    }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()

export default router
